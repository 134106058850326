<template>
  <div>
    <div class="mt-2">
      <div v-for="item in orderItems" :key="item.id" class="order-product-item mb-2">
        <span>{{ item.count || item.qty }} x </span>
        <span>{{ item.name }}&nbsp;</span>
        <span v-if="aggregator === 'wolt' || aggregator === 'fudy'" class="text-uppercase order-product-item-price">
          <i v-if="getOriginalItemPrice(item) > splitPrice(item.unit_price.amount)">
            &nbsp;{{ getOriginalItemPrice(item) }} </i
          >&nbsp; {{ splitPrice(item.unit_price.amount) }}&nbsp;{{ item.total_price.currency }}
        </span>
        <span v-if="aggregator === 'bolt'" class="text-uppercase order-product-item-price">
          <i v-if="item.unit_item_price.original_value">
            &nbsp;{{ formatPrice(item.unit_item_price.original_value) }} &nbsp;
          </i>
          {{ formatPrice(item.unit_item_price.value) }}&nbsp;{{ item.unit_item_price.currency }}
        </span>
        <div v-if="item.user_note" class="order-product-item-comment">{{ item.user_note }}</div>
        <template v-if="item.options.length > 0">
          <p class="pl-4 mt-2 mb-2">OPTIONS:</p>
          <p class="pl-4 mt-2 mb-2" v-for="option in item.options" :key="option.id">
            <span v-if="!(/\d\sx\s/.test(option.value) || /\d\sx\s/.test(option.name))"
              >{{ option.count || option.qty }} x
            </span>
            <span>{{ option.value || option.name }}</span>
            <span v-if="aggregator === 'wolt' || aggregator === 'fudy'" class="text-uppercase">
              ({{ splitPrice(option.price.amount) }} {{ option.price.currency }})
            </span>
            <span v-if="aggregator === 'bolt'" class="text-uppercase order-product-option-price">
              (
              <i v-if="option.unit_item_price.original_value">
                &nbsp;{{ formatPrice(option.unit_item_price.original_value) }} &nbsp;
              </i>
              {{ formatPrice(option.unit_item_price.value) }} {{ option.unit_item_price.currency }}
              )
            </span>
          </p>
        </template>
      </div>
    </div>
    <p class="mt-2 d-flex justify-space-between order-price-items">
      <span v-if="orderItems"
        >{{ orderItems.length }}
        <span v-if="orderItems.length < 2">item</span>
        <span v-else>items</span>
      </span>
      <span v-if="+originalPrice > 0 && +originalPrice != price" class="order-product-item-price">
        <i>&nbsp;{{ formatPrice(originalPrice) }}&nbsp;</i>&nbsp;{{ formatPrice(price) }}
        <span class="text-uppercase">{{ priceCurrency }}</span>
      </span>
      <span v-else-if="price">
        {{ formatPrice(price) }} <span class="text-uppercase">{{ priceCurrency }}</span>
      </span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'OrderItems',
  props: ['orderItems', 'price', 'originalPrice', 'priceCurrency', 'aggregator'],
  methods: {
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    splitPrice(value) {
      return (value / 100).toFixed(2);
    },
    getOriginalItemPrice(item) {
      const originalItemPrice = JSON.parse(item.pos_id)?.originalPrice ? JSON.parse(item.pos_id)?.originalPrice : 0;
      const optionsPrice =
        item.options.length > 0
          ? item.options.reduce((acc, option) => {
              acc += option.price.amount;
              return acc;
            }, 0)
          : 0;
      return ((originalItemPrice * 100 + optionsPrice) / 100).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
.order-price-items {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 0;
  span {
    display: inline-block;
    margin-right: 10px;
  }
}

.order-product-item {
  color: #333;
  font-size: 16px;
  font-weight: 600;
  &-comment {
    display: inline-block;
    background-color: #333;
    color: #f6f5fa;
    border-radius: 10px;
    padding: 4px 16px;
    font-size: 14px;
    letter-spacing: 0.3px;
    margin-left: 10px;
  }

  &-price i {
    position: relative;
    color: gray;

    &::after {
      border-bottom: 0.2em solid gray;
      content: '';
      left: 0;
      margin-top: calc(0.2em / 2 * -1);
      position: absolute;
      right: 0;
      top: 50%;
      rotate: -5deg;
    }
  }
}

.order-product-option {
  &-price i {
    position: relative;
    color: gray;

    &::after {
      border-bottom: 0.2em solid gray;
      content: '';
      left: 0;
      margin-top: calc(0.2em / 2 * -1);
      position: absolute;
      right: 0;
      top: 50%;
      rotate: -5deg;
    }
  }
}
</style>
